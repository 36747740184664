<template>
	<v-dialog :value="true" min-width="300" max-width="600" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">
		<v-card>

			<v-card-title>{{ item.id ? 'Edit' : 'Add' }} Project Alert</v-card-title>

			<v-card-text>
				<v-alert v-if="modalErrorMessage" color="red" border="bottom" class="my-3" v-html="modalErrorMessage"></v-alert>

				<div class="d-flex align-center my-3">
					Alert type:
					<v-radio-group v-model="item.client_or_project" hide-details dense row class="ml-3 my-0">
						<v-radio value="client" label="Client"></v-radio>
						<v-radio value="project" label="Project"></v-radio>
					</v-radio-group>
				</div>

				<project-picker v-if="item.client_or_project==='project'" v-model="item.project_id" class="my-3" label="Project*" placeholder="Select One..." :disabled="saving" dense hide-details outlined>
					<template v-slot:item="{item}">
						<span class="mr-2">{{item.name}}</span>
						<v-chip small v-if="item.status != 'active'" class="ml-auto">{{item.status}}</v-chip>
					</template>
				</project-picker>

				<client-picker v-if="item.client_or_project==='client'" v-model="item.client_id" label="Client*" placeholder="Select One..." :disabled="saving" dense class="my-3" hide-details outlined></client-picker>

				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field label="Hours" v-model="item.limit" :disabled="saving" dense hide-details outlined class="my-3"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<v-select dense outlined hide-details label="Hours time period" v-model="item.time_period" :items="time_period" class="my-3"></v-select>
					</v-col>
				</v-row>

				<email-picker v-model="item.email_recipients" :search-input.sync="search" label="Send emails to" placeholder="Select One..." :disabled="saving" dense class="my-3" hide-details outlined multiple chips small-chips deletable-chips></email-picker>

			</v-card-text>

			<v-card-actions>
				<v-btn :disabled="saving" @click="modal.trigger('close')" class="ml-auto">Close</v-btn>
				<v-btn color="primary" :disabled="saving" @click="submit">
					{{ item.id ? 'Update' : 'Create' }}
					<i v-if="saving" class="fa fa-cog fa-spin"></i>
				</v-btn>
			</v-card-actions>

		</v-card>
	</v-dialog>
</template>

<script>
	import Insight from "@/utils/Insight";
	import ProjectPicker from "@/components/fields/projectPicker.vue";
	import ClientPicker from "@/components/fields/clientPicker.vue";
	import EmailPicker from "@/components/fields/emailPicker";

	export default {
		name: "projectAlertAddEdit",

		props: {
			modal: {
				type: Object,
				required: true,
			},
			sourceData: {
				type: Object,
				default: function () {
					return {
						id: null,
						client_or_project: "project",
						client_id: null,
						project_id: null,
						email_recipients: null,
						time_period: "weekly",
						limit: null,
					};
				},
			},
		},

		components: { ProjectPicker, ClientPicker, EmailPicker },
		
		data() {
			return {
				search: "",
				modalErrorMessage: false,
				saving: false,
				item: Object.assign({}, this.sourceData),
				time_period: [
					{
						value: "weekly",
						text: "Per week",
					},
					{
						value: "monthly",
						text: "Per month",
					},
				],
			};
		},

		computed: {
		},

		methods: {
			submit() {
				this.saving = true;

				// validate
				let e = {
					model: this.item,
					cancel: false,
					errorMsg: false,
				};
				this.modal.trigger("validate", e);
				if (e.cancel) {
					this.saving = false;
					this.modalErrorMessage = e.errorMsg;
					return;
				}

				// TODO: convert to GQL
				let method = "post",
					api = `/api/alert`;
				if (this.item.id) {
					method = "put";
					api = `/api/alert/${this.item.id}`;
				}

				this.$xhrRequest
					.send(method, api, this.item)
					.on("success", (e, response) => {
						this.modal.trigger("save close", response);
					})
					.on("error", (e, xhr) => {
						this.saving = false;
						e.preventDefault();

						let errors = this.$insight.helpers.getRequestErrors(xhr);
						errors = this.$insight.helpers.flattenRequestErrors(errors);

						this.modalErrorMessage = errors.join("<br>");
					});
			},
		},

		watch: {
			"item.email_recipients"() {
				this.search = "";
			},
		}
	};
</script>
<style lang="scss" scoped>
</style>
